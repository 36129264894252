import Axios from 'axios';

import { store } from './redux';

//const BASE_HOST='http://localhost:3000';
const BASE_HOST='https://live.leaidou.top';



let token = null;

export const setToken = (t)=>{
    token = t;
}
export const getToken = ()=>{
    if(store.getState().loginInfo)return store.getState().loginInfo;
    if(token)return token;
    const str= localStorage.getItem('oauth');
    if(str){
        const oa = JSON.parse(str);
        token = oa.data;
        return token;
    }
    else return {};
    
}

const uggPost=(url,param)=>{
    return Axios.post(url,param,{
        headers:{
            'Authorization':  'Bearer '+getToken().access_token
        }
    })    
}

const uggGet=(url)=>{
    return Axios.get(url,{
        headers:{
            'Authorization':  'Bearer '+getToken().access_token
        }
    })
}

const uggPut = (url,param)=>{
    return Axios.put(url,param,{
        headers:{
            'Authorization':  'Bearer '+getToken().access_token
        }
    })    
}

export const checkLogin = (token)=>{
    setToken(token)
    const url = BASE_HOST+'/api/v1/check';
    return uggPost(url,{})
}

export const login=(options)=>{
    //todo
    const url =BASE_HOST+'/api/v1/token';
    options.grant_type='password';

    return Axios.post(url,options,{
        headers:{
            'Authorization':  'Basic ZnVsaXZlOmFwcA=='
        }
    }).then(ret=>{
        localStorage.setItem('oauth',JSON.stringify(ret.data));
        setToken(ret.data);
        return ret;
    })
}



export const usersig=(options)=>{
    //todo
    const url =BASE_HOST+'/api/v1/usersig';

    return Axios.get(url,{
        headers:{
            'Authorization':  'Bearer '+getToken().access_token
        }
    }).then(data=>{
        console.log(data);
        return data;
    })
}

export const register = (options)=>{
    const url = BASE_HOST+'/api/v1/signup';
    return Axios.post(url,options,{
        headers:{
            'Authorization':  'Bearer f78492f0ddf1b1739cd0d4e6e7e692cf2c47f82a'
        }
    })

}

export const getVerifyCode = (options)=>{
    const url = BASE_HOST+'/api/v1/send/code';
    return Axios.post(url,options,{
        headers:{
            'Authorization':  'Bearer f78492f0ddf1b1739cd0d4e6e7e692cf2c47f82a'
        }
    })
}

export const getRooms = (options)=>{
    const url = BASE_HOST+'/base/v1/rooms/query_room';
    
    return  Axios.get(url);
}

export const giveGift =(toUser,giftId,giftCount,giftPrice)=>{
    const param = {
        gift_id:giftId,
        to_user_id:toUser,
        giftCount:giftCount||1,
        giftPrice
    }
    const url = BASE_HOST+'/api/v1/give';
    console.log('==>giveGift:',param);
    return uggPost(url,param).then(ret=>{
        console.log(ret);
        return ret.data;
    }).catch(err=>{
        console.error(err);
        throw err;
    })
}

export const getProfile = (id)=>{
    if(id){
        const url = BASE_HOST+'/api/v1/profile/'+id;
        return uggGet(url).then(ret=>{
            return ret.data;
        });
    }
    else {
        const url = BASE_HOST+'/api/v1/profile';
        return uggGet(url).then(ret=>{
            return ret.data;
        });
    }
    
}

export const getAccount = ()=>{
    const url =BASE_HOST+ '/api/v1/account'
    return uggGet(url).then(ret=>{
        return ret.data;
    })
}

export const createOrder = (param)=>{
    const url = BASE_HOST+'/api/v1/order';
    return uggPost(url,param);
}

export const payOrder = (id)=>{
    const url = BASE_HOST+'/api/v1/pay/'+id;
    return uggPost(url,{});
}

export const loadCircles=(page)=>{
    const url = BASE_HOST+'/api/v1/circles'+(page?'?p='+page:'');
    return uggGet(url);
}

export const loadFeeds = (next_page=0)=>{
    const url = BASE_HOST+'/api/v1/feeds?p='+next_page;

    return uggGet(url).then(ret=>{
        return ret
    });
}

export const loadFeed =(id)=>{
    const url = `${BASE_HOST}/api/v1/feed/${id}`;
    return uggGet(url).then(ret=>{
        return ret.data;
    })
}


export const uploadImage =(file)=>{

    const {loginInfo} = store.getState();
    if(!loginInfo)return;

    let formData = new FormData();
    formData.append('pic',file);

    // console.log('uri:',localUri);

    return Axios.post(BASE_HOST+'/api/v1/upload/photos', formData, {
        headers: { 
            'Content-Type': 'multipart/form-data',
            'Authorization': `Bearer ${loginInfo.access_token}`
        },
    }).then(ret=>{
        console.log('[debug]upload:',ret);
        return ret.data;
    }).catch(err => {
        console.log(err.response);
    }); 
}

export const updateProfile=(nickname,avatar,bio)=>{
    return uggPut(BASE_HOST+'/api/v1/profile',{
        nickname,
        avatar,
        bio
        // background:backUrl
    }).then(ret=>ret.data);
}

export const loadAccountHistory = (showIncome)=>{
    return uggGet(BASE_HOST+'/api/v1/account_history?q='+(showIncome?'income':'expend')).then(ret=>{
        return ret.data;
    })
}

export const withdraw = (amount,name,to_wallet)=>{
    return uggPost(BASE_HOST+'/api/v1/withdraw',{
        amount,
        name,
        to_wallet
    }).then(ret=>{
        return ret.data;
    })
}

export const search = (criteria)=>{
    return uggGet(BASE_HOST+'/api/v1/search?q='+criteria).then(ret=>{
        return ret.data;
    })
}

export const loadCircle =(id)=>{
    return uggGet(BASE_HOST+'/api/v1/circle_detail/'+id).then(ret=>{
        return ret.data;
    })
}

export const joinCircle = (id)=>{
    const url = `${BASE_HOST}/api/v1/join_circle/${id}`;
    return uggPut(url,{}).then(ret=>{
        return ret.data
    })
}

export const loadMyCircles = ()=>{
    const url = `${BASE_HOST}/api/v1/my_circles`;
    return uggGet(url).then(ret=>{
        return ret.data;
    })
}

export const leaveCircle = (circle_id)=>{
    const url = `${BASE_HOST}/api/v1/leave_circle/${circle_id}`;
    return uggPut(url,{}).then(ret=>{
        return ret.data;
    })
}

export const report=(id,type,desc)=>{
    console.log('[DEBUG]!!!!!!!!!!!report:',id,type,desc);

    const url = `${BASE_HOST}/api/v1/report`;
    return uggPost(url,{item_id:id,category:type,desc}).then(ret=>{
        return ret.data;
    })
}

export const createCircle = (title,avatar,bio)=>{
    const url = `${BASE_HOST}/api/v1/circle`;
    return uggPost(url,{
        title,avatar,bio
    }).then(ret=>{
        return ret.data;
    })
}

export const createPayCircle = (params) =>{
    const url = `${BASE_HOST}/api/v1/circle`;
    return uggPost(url,params).then(ret=>{
        return ret.data;
    })
}

export const reward = (money,circle_id)=>{
    const url = `${BASE_HOST}/api/v1/reward_circle`;
    return uggPost(url,{
        amount:money,
        circle_id,
        payment_method:'alipay'
    }).then(ret=>{
        return ret.data;
    })
}

export const recharge = (money)=>{
    const url = `${BASE_HOST}/api/v1/recharge`;
    return uggPost(url,{amount:money,payment_method:'alipay'}).then(ret=>{
        return ret.data;
    })
}

export const feedback = (content)=>{
    const url = `${BASE_HOST}/api/v1/feedback`;
    return uggPost(url,{content}).then(ret=>{
        return ret.data;
    })
}

export const loadComments = (id)=>{
    const url = `${BASE_HOST}/api/v1/comments/${id}`;
    return uggGet(url).then(ret=>{
        return ret.data;
    })
}

export const comment=(id,content)=>{
    const url = `${BASE_HOST}/api/v1/comment/${id}`;
    return uggPost(url,{content}).then(ret=>{
        return ret.data;
    })
}
